import React from 'react';
import Helmet from 'react-helmet';
const Config = require('../config');

const IndexPage = (props) => (
  <Helmet>
    <meta http-equiv="refresh" content={`0;url=/${Config.Lang.DefaultPath}/`} />
  </Helmet>
)

export default IndexPage
